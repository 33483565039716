@import '__importable.scss';
.container {
	padding: 1.6rem;
	border-radius: $radius-4;
	background-color: $gray-1;

	@media screen and (min-width: $media-sm) {
		padding: 1.6rem;
	}
}

.form {
	flex-direction: column;

	@media screen and (min-width: $media-md) {
		flex-direction: row;
	}
}

.bundleTitle {
	font-size: 1.6rem !important;
	font-weight: 500 !important;
}

.select {
	width: 100% !important;
	flex-grow: 1;

	@media screen and (min-width: $media-md) {
		max-width: 50rem;
	}
}

.placeholder {
	:first-child span {
		color: $gray-4 !important;
	}
}

.select-button {
	width: 100%;
	margin-bottom: 0 !important;
	justify-content: space-between !important;
}

.defaults {
	display: flex;
}

.default-option {
	border: 0 !important;
	background-color: transparent !important;
	font-size: 1.4rem;

	button {
		background-color: transparent;
	}

	&:hover {
		@media (hover: hover) {

			&:hover,
			&:active,
			&:focus-visible {
				border-color: $gray-2;
				box-shadow: none !important;
				background-color: $gray-0;

				> * {
					transform: none;
				}
			}
		}
	}
}

.measure-button {
	height: auto;
	min-width: 9.4rem;
	font-size: 1.4rem;
}

.disabled {
	flex-grow: 1;
}
