@import '__importable.scss';
.container {
	width: 100%;
	height: 100%;
	margin-bottom: 0.8rem;
	display: flex;
	flex-flow: column;

	&--center,
	&--center > * {
		justify-content: center;
	}

	&--left,
	&--left > * {
		justify-content: flex-start;
	}

	&--right,
	&--right > * {
		justify-content: flex-end;
	}
}

.list {
	display: flex;
	align-items: center;
	gap: 0.3rem;
	padding: 0;
	padding-left: 0.4rem;
	min-height: 4.8rem;
	margin: 0;
	position: relative;
	left: -2px;
}

.label {
	font-size: 1.6rem;
	line-height: 2.4rem;
	font-weight: 500;
}

.swatchContainer {
	z-index: 1;
	@media screen and (max-width: $media-lg-max) {
		overflow-x: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.description {
	font-size: 1.4rem;
	align-self: center;
	font-weight: 400;
}
