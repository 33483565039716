@import '__importable.scss';
$border-radius-default: 1.6rem;
$border-radius: 1rem;
$padding-default: 1.6rem;
$gap-small: 0.8rem;
$gap-medium: 1rem;

.container {
	border: 0.01rem solid $gray-2;
	border-radius: $border-radius-default;
	background: $gray-0;
	padding: $padding-default;
	width: 100%;
	max-width: 49rem;
	gap: $gap-small;

	.copy{
		margin-top: 0.8rem;
		width: 21rem;
		gap: $gap-medium;
	}

	span{
		font-weight: 400;
	}

	.membershipOption{
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		border-radius: 1rem;
		border: 1px solid $gray-5;
		&--copy{
			cursor: pointer;
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-bottom: 0.9rem;
			gap: $gap-medium;
			opacity: 0.3;
			transition: opacity 0.1s ease, background 0.1s ease;

			&:first-child {
				border-radius: $border-radius 0 0 $border-radius;
			}

			&:last-child {
				border-radius: 0 $border-radius $border-radius 0;
			}

			button{
				padding: 0;
				position: absolute;
				top: 1rem;
				left: 1rem;
				height: auto;
				background-color: inherit;
			}

			.title{
				width: 100%;
				text-align: center;
				align-content: center;
				border-bottom: 1px solid $gray-5;
				height: 2.8rem;
			}
			
			&--active{
				opacity: 1;
				background: $lightblue-2;
			}

			.save{
				background: $blue-1;
				width: 7.5rem;
				height: 2.5rem;
				text-align: center;
				align-content: center;
				color: $gray-0;
			}

		}
	}
}
