@import '__importable.scss';
.container {
	background-color: white;
	border: 1px solid $gray-2;
	margin: auto;
	padding: 1.6rem;
	border-radius: $radius-6;
	height: 100%;

	.bundleTitle {
		margin-top: 1.6rem;
		font-size: 1.6rem !important;
		font-weight: 500 !important;
	}
}

.usabilityContainer {
	background-color: white;
	border: 1px solid $gray-2;
	margin: auto;
	padding: 1.6rem;
	border-radius: $radius-6;
	height: 100%;
}
.bundleTitle {
	margin-top: 1.6rem;
	font-size: 1.6rem !important;
	font-weight: 500 !important;
	line-height: 2.4rem;
}
.title {
	font-size: 2rem !important;
	font-family: var(--platform) !important;

	@media screen and (max-width: $mq-xsmall) {
		margin-top: 0;
	}
}

.containerInDrawer {
	background-color: white;
	border: 1px solid $gray-2;
	margin: auto;
	padding: 1.6rem;
	border-radius: $radius-6;
	height: 100%;

	> p:first-child {
		margin-top: 1.6rem;
		padding: 0 0.8rem;
	}
}

.deleteBaseFrameModal {
	max-width: 45rem !important;

	.imageContainer {
		background-color: $gray-1;
		width: 100%;
		height: 22.5rem;
		border-bottom: 1px solid $gray-2;

		> img {
			mix-blend-mode: multiply;
			max-width: 30rem;
		}
	}

	.bodyContainer {
		padding: 1.6rem;

		> p {
			margin-bottom: 2.4rem;
		}
	}
}

.readerStrengthDropdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 0.8rem;
	padding: 0.8rem 0.8rem 0.8rem 1.6rem;
	background-color: $gray-1;
}

.swiper-container {
	overflow: hidden !important;
	max-width: 311px;
	ul {
		height: 100% !important;

		li {
			height: 100% !important;
		}
	}

	@media screen and (max-width: $mq-small) {
		border-radius: unset;
	}
}

.lensUpsellIncentive {
	color: $green-tint;
}

.section {
	padding: 1.6rem;
	border-radius: $radius-4;
	background-color: $gray-1;
}

.usabilitySection {
	padding: 1.6rem;
	border-radius: $radius-6;
	background-color: $gray-1;
}

.usabilityDarkSection {
	padding: 1.6rem;
	border-radius: $radius-6;
	background-color: $gray-1;
}

.transparent {
	background-color: transparent !important;
}

.lensPackage {
	font-size: 1.2rem;
	font-weight: 500;
}

.info {
	svg {
		width: 3.2rem;
		height: 3.2rem;
	}
}

.link {
	text-decoration: underline;
}
