@import '__importable.scss';
.lozenge {
	display: flex;
	height: fit-content;
	width: fit-content;
	align-items: center;
	color: $gray-0;
	background-color: $blue-2;
	font-weight: 500;
	font-family: var(--platform);
	font-size: 1.2rem;
	line-height: 1.4rem;
	padding: 0.2rem 0.4rem;
	border-radius: $radius-1;
	z-index: $zindex-1;

	&--pill {
		border-radius: $radius-pill;
		padding: 0.2rem 0.8rem;
	}

	&--square {
		border-radius: $radius-1;
	}

	&--left {
		position: absolute;
		left: 1.6rem;
		top: 1.6rem;
	}

	&--right {
		position: absolute;
		top: 1.6rem;
		right: 1.6rem;
	}

	&--center {
		position: absolute;
		top: 1.6rem;
		left: 50%;
		transform: translate(-50%);
	}

	&--regular {
		// Only for the lozenge in the navbar
		@media screen and (min-width: $media-md) {
			font-size: 1.6rem;
			line-height: 2.1rem;
		}
	}

	a,
	span {
		color: inherit;
		display: flex;
		align-items: center;
		gap: 0.4rem;
		font-family: inherit;

		&:empty {
			display: none;
		}
	}

	&.limited-edition {
		background: $yellow;

		p {
			color: $black;
		}
	}

	&.coming-soon {
		background: $blue-tint;

		p {
			color: white;
		}
	}

	&.just-launched {
		background: $yellow;

		p {
			color: black;
		}
	}
}

.icon{
	margin-left: 0.8rem;
}

.logo{
	margin-right: 0.8rem;
}