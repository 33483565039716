@import '__importable.scss';
.fileName {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 72px;

	@media screen and (min-width: $media-sm) {
		text-overflow: unset;
		white-space: unset;
		overflow: unset;
		width: unset;
	}
}

.option {
	padding: 1.6rem;
	display: flex;
	align-items: center;
}

.checked {
	border-color: $blue-1 !important;
}

.usabilityWrapper {
	padding-inline: 3.2rem !important;
	padding-block: 1.2rem !important;
	align-items: center !important;
	flex: 1;
}
