@import '__importable.scss';
.pause-play-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 3.2rem;
	height: 3.2rem;
	left: 1rem;
	right: auto;
	bottom: 3%;
	top: unset;
	padding: 0;
	margin-top: calc($swiper-navigation-size / -2);
	color: $blue-2;
	background-color: $gray-0;
	border: 1.5px solid $gray-2;
	border-radius: $radius-round;
	transition: background-color 300ms ease;
	z-index: $zindex-10;
	cursor: pointer;

	svg {
		height: 1.6rem;
		stroke: $gray-5;
	}
	&--top-right {
		left: auto;
		right: 1rem;
		top: 3rem;
	}
}
