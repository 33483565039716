@import '__importable.scss';
.modal {
	max-width: 38rem;
	height: 50rem;
	max-height: none;
	background-color: $lightblue;
	padding-top: 0;

	p{
		font-size: 1.2rem !important;
	}
	.container{
		gap: 1.6rem;

		.header {
			height: 15rem;
			border-bottom: 1px solid $gray-5;
			justify-items: center;
			align-content: center;
		}

		.content{
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding-inline: 3.2rem;
			justify-content: center;
		}

		.button {
			width: fit-content;
			align-self: center;
		}
		
		.benefits {
			li {
			  font-size: 1.2rem;
			  margin: 5px 0;
			  &::before {
				content: "— ";
			  }
			}
		}
	}

	.close {
		position: absolute;
		top: 2rem;
		right: 2rem;
		border: 0.1rem solid $gray-5;
		border-radius: 50%;
		display: flex;
		width: 2.2rem;
		height: 2.2rem;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			background-color: $lightblue;
		}
	}
}
