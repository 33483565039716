@import '__importable.scss';
.modal {
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	bottom: unset !important;
	padding: 3rem 0 1rem !important;
	border-radius: 1.6rem !important;
	width: calc(100vw - 2.4rem) !important;
}

.modalButton {
	display: none;

	&--top-right {
		position: absolute;
		right: 0;
		top: 0;
	}

	&--bottom-right {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&--top-left {
		position: absolute;
		left: 0;
		top: 0;
	}

	&--bottom-left {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	@media screen and (max-width: $media-sm) {
		display: block;
	}
}

.button {
	@include roundActionButton;
	&--square {
		@include hoverable($gray-3);
		border: 1px solid $gray-2;
		height: 4.2rem;
		border-radius: 0.4rem;
		@media (hover: hover) {
			&:hover:not(:active):not(:focus-visible) {
				background-color: $gray-1;
			}
		}
	}
}

.bottomMessage {
	bottom: 1rem;
	text-align: center;
	position: sticky;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;

	> p {
		color: $gray-4;
		margin-left: 0.8rem;
	}
}

.imageContainer {
	overflow: scroll;
	padding: 0 2rem;

	@media screen and (min-width: $media-md) {
		display: flex;
		flex-direction: column;
		align-items: center;
		.bottomMessage {
			display: none;
		}
	}

	.image {
		max-width: 180%;
		margin: 0 1.6rem;
		height: 100%;
	}
}
